<template>
  <b-modal
    id="modal-edit-contract-template"
    cancel-variant="outline-secondary"
    centered
    size="lg"
    :title="`${isEdit ? '修改' : '新增'}合約模版`"
    :ok-disabled="false"
    hide-footer
    @hide="resetModal"
  >
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form
          class="contract-template-form"
          @submit.prevent="handleSubmit(onSubmit)"
        >
          <b-row>
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="模版名稱"
                rules="required"
              >
                <b-form-group
                  label="模版名稱"
                  label-for="name"
                  class="required"
                  label-cols-md="3"
                >
                  <b-form-input
                    class=""
                    v-model="newContractTemplateData.name"
                    :state="getValidationState(validationContext)"
                    placeholder="模版名稱"
                  ></b-form-input>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12">
              <b-form-group label="上傳模版" label-for="file" label-cols-md="3">
                <b-form-file
                  placeholder="請選擇模版(*必須上傳docx檔)"
                  v-model="newContractTemplateData.file"
                  accept=".docx"
                >
                </b-form-file>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label="排序"
                label-for="sort_order"
                label-cols-md="3"
              >
                <b-form-input
                  class=""
                  v-model="newContractTemplateData.sort_order"
                  placeholder="排序"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label="狀態"
                label-for="is_active"
                label-cols-md="3"
              >
                <b-form-checkbox
                  :checked="newContractTemplateData.is_active"
                  name="is_active"
                  switch
                  inline
                  v-model="newContractTemplateData.is_active"
                  value="1"
                  style="margin-top: 5px"
                >
                  {{ newContractTemplateData.is_active ? "啟用" : "禁用" }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <div class="mt-2">
                <div class="d-flex justify-content-between">
                  <h5>自定義合約內容</h5>
                  <b-button variant="primary" @click="addContent"
                    >新增內容</b-button
                  >
                </div>
                <div
                  v-for="(content, index) in newContractTemplateData.contents"
                  :key="index"
                  class="mt-2"
                >
                  <b-row>
                    <b-col cols="2">
                      <b-form-input
                        v-model="content.order"
                        placeholder="順序"
                      />
                    </b-col>
                    <b-col cols="8">
                      <b-form-textarea
                        v-model="content.content"
                        placeholder="內容"
                        rows="3"
                      />
                    </b-col>
                    <b-col cols="2">
                      <b-button variant="danger" @click="removeContent(index)"
                        >刪除</b-button
                      >
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-col>

            <b-col
              cols="12"
              class="d-flex flex-wrap justify-content-center w-100 p-2"
            >
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1 px-3"
                type="submit"
              >
                提交
              </b-button>

              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                class="px-3"
                @click="hide"
              >
                取消
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-overlay>
  </b-modal>
</template>

<script>
import Ripple from "vue-ripple-directive";
import store from "@/store";
import vSelect from "vue-select";
import { ref, watch, onUnmounted, onMounted } from "@vue/composition-api";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
localize("zh_cn", zh_CN);
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, alphaNum, email } from "@validations";
import flatPickr from "vue-flatpickr-component";

import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

import {
  BModal,
  BButton,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BForm,
  BFormGroup,
  BFormFile,
  BFormCheckbox,
  BFormTextarea,
  BFormInvalidFeedback,
  BOverlay,
} from "bootstrap-vue";

export default {
  components: {
    BModal,
    BButton,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BForm,
    BFormGroup,
    BFormFile,
    BFormCheckbox,
    BFormInvalidFeedback,
    BOverlay,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
    };
  },
  props: {
    templateData: {},
    isEdit: {},
  },
  methods: {
    hide() {
      this.$bvModal.hide("modal-edit-contract-template");
    },
    resetModal() {
      this.$emit("fouceIsEditFalse");
    },
    onSubmit() {
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          let formData = new FormData();
          formData.append("id", this.newContractTemplateData.id);
          formData.append("name", this.newContractTemplateData.name);
          formData.append("file", this.newContractTemplateData.file);
          formData.append(
            "contents",
            JSON.stringify(this.newContractTemplateData.contents)
          );
          formData.append(
            "sort_order",
            this.newContractTemplateData.sort_order
          );
          formData.append(
            "is_active",
            this.newContractTemplateData.is_active ? 1 : 0
          );

          store
            .dispatch("contract-template/editContractTemplate", formData)
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
              this.hide();
              this.loading = false;
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.loading = false;
            });
        }
      });
    },
    addContent() {
      if (!this.newContractTemplateData.contents) {
        this.newContractTemplateData.contents = [];
      }
      this.newContractTemplateData.contents.push({
        order: this.newContractTemplateData.contents.length + 1,
        content: "",
      });
    },
    removeContent(index) {
      if (
        this.newContractTemplateData.contents &&
        this.newContractTemplateData.contents.length > 0
      ) {
        this.newContractTemplateData.contents.splice(index, 1);
        // 重新排序
        this.newContractTemplateData.contents.forEach((item, i) => {
          item.order = i + 1;
        });
      }
    },
  },
  watch: {},
  setup(props) {
    const loading = ref(false);
    const newContractTemplateData = ref({
      id: 0,
      is_active: 1,
      contents: [],
    });

    const resetContractTemplateData = () => {
      newContractTemplateData.value = { id: 0, is_active: 1, contents: [] };
    };
    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetContractTemplateData
    );

    watch(
      () => props.templateData,
      (newVal) => {
        newContractTemplateData.value = newVal;
      }
    );
    return {
      refFormObserver,
      getValidationState,
      resetForm,
      loading,
      newContractTemplateData,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
