<template>
  <div>
    <modal-pdf id="-preview-contract" :pdfUrl="pdfUrl" />
    <modal-contract-template-remark />
    <modal-edit-contract-template
      :isEdit="isEdit"
      @fouceIsEditFalse="fouceIsEditFalse"
      :templateData="templateData"
      @refetch-data="refetchData"
    />

    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="搜索合約模版..."
              />
              <b-button
                variant="primary"
                class="mr-1"
                @click="$bvModal.show('modal-contract-template-remark')"
              >
                <feather-icon icon="AlertCircleIcon" size="13" />
              </b-button>
              <b-button
                variant="primary"
                @click="$bvModal.show('modal-edit-contract-template')"
                v-if="ability.can('create', 'contract-templates')"
              >
                <span class="text-nowrap">新增合約模版</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <!-- b-table-->
      <b-table
        ref="refContractTemplateListTable"
        class="position-relative"
        :items="fetchContractTemplates"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: is_active -->
        <template #cell(is_active)="data">
          <feather-icon
            v-if="data.item.is_active"
            icon="CheckIcon"
            size="18"
            class="mr-50 text-success"
          />
        </template>

        <template #cell(created_at)="data">
          {{ getCorrectDateTime(data.item.created_at, true) }}
        </template>

        <template #cell(action)="data">
          <a
            v-if="ability.can('update', 'contract-templates')"
            href="javascript:void(0)"
            @click="handleEdit(data.item)"
          >
            <feather-icon class="color1" icon="Edit2Icon" />
          </a>
          <span class="mx-50 color1">|</span>
          <a
            v-if="ability.can('delete', 'contract-templates')"
            href="javascript:void(0)"
            @click="confirmDelete(data.item.id)"
          >
            <feather-icon class="color1" icon="Trash2Icon" />
          </a>
          <span class="mx-50 color1">|</span>
          <a href="javascript:void(0)" @click="downloadTemplate(data.item.id)">
            <feather-icon class="color1" icon="DownloadCloudIcon" />
          </a>
        </template>

        <template #cell(preview)="data">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-primary"
            class="btn-icon"
            target="_blank"
            @click="previewContract(data.item.id)"
          >
            <feather-icon icon="FileTextIcon" />
          </b-button>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRow"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBPopover,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";

import contractStoreModule from "../contractStoreModule";
import useContractTemplatesList from "./useContractTemplatesList";
import ModalEditContractTemplate from "../modal/ModalEditContractTemplate.vue";
import ModalContractTemplateRemark from "../modal/ModalContractTemplateRemark.vue";
import ModalPdf from "@/layouts/components/ModalPdf.vue";

export default {
  components: {
    BImg,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ModalEditContractTemplate,
    ModalContractTemplateRemark,
    ModalPdf,
  },
  directives: {
    "b-popover": VBPopover,
    Ripple,
  },
  methods: {
    handleEdit(item) {
      this.templateData = { ...item };
      this.isEdit = true;
      this.$bvModal.show("modal-edit-contract-template");
    },
    fouceIsEditFalse() {
      this.isEdit = false;
      this.templateData = { id: 0, is_active: 1 };
    },
    downloadTemplate(id) {
      // check number of record
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "下載",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("contract-template/fetchContractTemplate", { id })
            .then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", "template.docx"); // or any other extension
              document.body.appendChild(link);
              link.click();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    previewContract(id) {
      this.loading = true;
      this.$store
        .dispatch("contract-template/previewContract", { id })
        .then((response) => {
          const filepdf = new Blob([response.data], {
            type: "application/pdf",
          });
          const fileURL = URL.createObjectURL(filepdf);
          this.pdfUrl = fileURL;
          this.$bvModal.show("modal-pdf-preview-contract");
          // console.log(this.pdfUrl)
          this.loading = false;
        })
        .catch((error) => {
          console.log({ error });
          this.loading = false;
        });
    },
    confirmDelete(id) {
      // check number of record
      this.$swal({
        title: "你確定嗎?",
        text: "您的決定是最終決定，您將無法取回這記錄。",
        showCancelButton: true,
        confirmButtonText: "刪除",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("contract-template/deleteContractTemplate", { id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.refetchData();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  data() {
    return {
      templateData: {},
      isEdit: false,
      pdfUrl: "",
    };
  },
  setup() {
    const CONTRACT_STORE_MODULE_NAME = "contract-template";

    if (!store.hasModule(CONTRACT_STORE_MODULE_NAME))
      store.registerModule(CONTRACT_STORE_MODULE_NAME, contractStoreModule);

    onUnmounted(() => {
      if (store.hasModule(CONTRACT_STORE_MODULE_NAME))
        store.unregisterModule(CONTRACT_STORE_MODULE_NAME);
    });

    const {
      fetchContractTemplates,
      tableColumns,
      perPage,
      currentPage,
      totalRow,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      refContractTemplateListTable,
      showMessage,
      templateList,
      ability,
    } = useContractTemplatesList();

    return {
      fetchContractTemplates,
      tableColumns,
      perPage,
      currentPage,
      totalRow,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      refContractTemplateListTable,
      showMessage,
      templateList,
      ability,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
