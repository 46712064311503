<template>
    <b-modal id="modal-contract-template-remark" centered size="md" title="合約可用標籤">
        <b-card no-body class="mb-0">
            <p>
                在合約模板中，您可以使用下列標籤來標記需要填入的內容。當您生成合約時，這些標籤將被系統自動替換為相應的數據：
            </p>
            <b-table :items="data" :fields="tableColumns" class="table table-bordered table-hover table-sm"
                thead-class="thead-light">
                <template #cell(tag)="data">
                    <code>{{ data.value }}</code>
                </template>


            </b-table>
        </b-card>
    </b-modal>
</template>
<script>
import {
    BModal,
    BCard,
    BRow,
    BCol,
    BTable,
} from "bootstrap-vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    components: {
        BModal,
        BCard,
        BRow,
        BCol,
        BTable,
        ToastificationContent
    },
    data() {
        return {
            tableColumns: [
                { key: 'tag', label: '標籤' },
                { key: 'description', label: '替換內容' },
            ],
            data: [
                { tag: '${contract_date}', description: '合約日期' },
                { tag: '${start_date}',description: '合約開始日期'},
                { tag: '${end_date}', description: '合約完結日期'},
                { tag: '${company_name_cn}', description: '公司名稱中文' },
                { tag: '${company_name_en}', description: '公司名稱英文' },
                { tag: '${br_address}', description: '登記/通訊地址' },
                { tag: '${br_no}', description: '登記/商業登記號碼' },
                { tag: '${contact_name}', description: '聯絡人名稱' },
                { tag: '${contact_title}', description: '聯絡人職位' },
                { tag: '${contact_phone}', description: '聯絡人電話' },
                { tag: '${id_number}', description: '身份證號碼' },
               // { tag: '${contract_title}', description: '合約標題' },
            ]
        };
    },
    methods: {
        doCopy(valueToCopy) {
            console.log(valueToCopy);
            this.$copyText(valueToCopy).then(() => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: `已複制 ${valueToCopy} 到剪貼板`,
                        icon: 'BellIcon',
                    },
                });
            }, e => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Cannot copy!',
                        icon: 'BellIcon',
                    },
                });
            });
        },
    }
}


</script>
